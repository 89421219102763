

export const ERROR_COPY = `
<p>
  We are sorry, but you won't be able to play this game as your phone doesn't support speech to text capabilities.
</p>
<p>
  We are currently working hard to make this game cross phone compatible.
</p>
`;

export const TOPICS = [
  'entertainment',
  'games',
  'movies',
  'music',
  'people',
  'phrases',
];

export const ITEMS = [
  { topic: 'entertainment', phrase: 'my star seth', answer: 'masterchef' },
  { topic: 'entertainment', phrase: 'dane sing wither sars', answer: 'dancing with the stars' },
  { topic: 'entertainment', phrase: 'blake committee roar', answer: 'black mirror' },
  { topic: 'entertainment', phrase: 'pry sum brick', answer: 'prison break' },
  { topic: 'entertainment', phrase: 'stray jerk inns', answer: 'stranger things' },
  { topic: 'entertainment', phrase: 'brave king bed', answer: 'breaking bad' },
  { topic: 'entertainment', phrase: 'gain oval moans', answer: 'game of thrones' },
  { topic: 'entertainment', phrase: 'one king dad', answer: 'walking dead' },
  { topic: 'entertainment', phrase: 'dap beak gang eerie', answer: 'the big bang theory' },
  { topic: 'entertainment', phrase: 'maid amend', answer: 'mad men' },
  { topic: 'entertainment', phrase: 'come unit tee', answer: 'community' },
  { topic: 'entertainment', phrase: 'dork too hoot', answer: 'doctor who' },
  { topic: 'entertainment', phrase: 'thief lash', answer: 'the flash' },
  { topic: 'entertainment', phrase: 'reap ken more tea', answer: 'rick and morty' },
  { topic: 'entertainment', phrase: 'fart gone', answer: 'fargo' },
  { topic: 'movies', phrase: 'soup as tar', answer: 'superstar' },
  { topic: 'movies', phrase: 'ate vent germs', answer: 'avengers' },
  { topic: 'movies', phrase: 'stay wolves', answer: 'star wars' },
  { topic: 'movies', phrase: 'sow hound oval meow sick', answer: 'sound of music' },
  { topic: 'movies', phrase: 'pull perfect shine', answer: 'pulp fiction' },
  { topic: 'movies', phrase: 'their jaw jank read empty shine', answer: 'the shawshank redemption' },
  { topic: 'movies', phrase: 'wise hard ovals', answer: 'wizard of oz' },
  { topic: 'movies', phrase: 'their dart sight', answer: 'the dark knight' },
  { topic: 'movies', phrase: 'take x-ray try vert', answer: 'taxi driver' },
  { topic: 'movies', phrase: 'jerk rascal parcel ', answer: 'jurassic park' },
  { topic: 'movies', phrase: 'talk too strain', answer: 'doctor strange' },
  { topic: 'movies', phrase: 'their terminal paper', answer: 'the terminator' },
  { topic: 'movies', phrase: 'hoe aim loan', answer: 'home alone' },
  { topic: 'movies', phrase: 'race engine bee all', answer: 'raging bull' },
  { topic: 'movies', phrase: 'too store reak', answer: 'toy story' },
  { topic: 'music', phrase: 'goons are rows zeus', answer: 'guns n roses' },
  { topic: 'music', phrase: 'light tell raid', answer: 'little red' },
  { topic: 'music', phrase: 'blue dime lies', answer: 'blinding lights' },
  { topic: 'music', phrase: 'dans moon kate', answer: 'dance monkey' },
  { topic: 'music', phrase: 'sir cools', answer: 'circles' },
  { topic: 'music', phrase: 'weep wall rope kew', answer: 'we will rock you' },
  { topic: 'music', phrase: 'hip waiter tell', answer: 'highway to hell' },
  { topic: 'music', phrase: 'dane sing wither stray germ', answer: 'dancing with a stranger' },
  { topic: 'music', phrase: 'sue ogar end', answer: 'see you again' },
  { topic: 'music', phrase: 'own lie hugh amend', answer: 'only human' },
  { topic: 'music', phrase: 'growls just one torn haven', answer: 'girls just want to have fun' },
  { topic: 'music', phrase: 'too tall newsclip enter their heart', answer: 'total eclipse in the heart' },
  { topic: 'music', phrase: 'wendorf skies', answer: 'when doves cry' },
  { topic: 'music', phrase: 'hay eel the war led', answer: 'heal the world' },
  { topic: 'music', phrase: 'row oak which suit', answer: 'rock with you' },
  { topic: 'music', phrase: 'gang nasty kyle', answer: 'gangnam style' },
  { topic: 'people', phrase: 'jay key shine', answer: 'jackie chan' },
  { topic: 'people', phrase: 'rye antsy caress', answer: 'ryan seacrest' },
  { topic: 'people', phrase: 'talk to drain', answer: 'doctor dre' },
  { topic: 'people', phrase: 'tea nut turf are', answer: 'tina turner' },
  { topic: 'people', phrase: 'dough null dump', answer: 'donald trump' },
  { topic: 'people', phrase: 'bee ill gray apes', answer: 'bill gates' },
  { topic: 'people', phrase: 'mine call jaw damp', answer: 'michael jordan' },
  { topic: 'people', phrase: 'laid ear far car', answer: 'lady gaga' },
  { topic: 'people', phrase: 'tait lords wept', answer: 'taylor swift' },
  { topic: 'people', phrase: 'kneel some dalai lama ', answer: 'nelson mandela' },
  { topic: 'people', phrase: 'there oak', answer: 'the rock' },
  { topic: 'people', phrase: 'range leaner jawline', answer: 'angelina jolie' },
  { topic: 'people', phrase: 'wales my trick', answer: 'will smith' },
  { topic: 'people', phrase: 'geoff antelopes', answer: 'jennifer lopez' },
  { topic: 'people', phrase: 'jaw jet envolopes', answer: 'george lopez' },
  { topic: 'people', phrase: 'steak vans pill purge', answer: 'steven spielberg' },
  { topic: 'people', phrase: 'leak narcos decapitated trio', answer: 'leonardo dicaprio' },
  { topic: 'phrases', phrase: 'bee gig chia ease', answer: 'big cheese' },
  { topic: 'phrases', phrase: 'bed boring keys', answer: 'bad books' },
  { topic: 'phrases', phrase: 'afloat enter the adore', answer: 'a foot in the door' },
  { topic: 'phrases', phrase: 'yell oh breek rude', answer: 'yellow brick road' },
  { topic: 'phrases', phrase: 'toot cot candle', answer: 'too hot to handle' },
  { topic: 'phrases', phrase: 'bum pan green', answer: 'bump and grind' },
  { topic: 'phrases', phrase: 'shoe meter monday', answer: 'show me the money' },
  { topic: 'phrases', phrase: 'took all fiscal', answer: 'too cool for school' },
  { topic: 'phrases', phrase: 'mate kit rate', answer: 'make it rain' },
  { topic: 'phrases', phrase: 'hard monday hinge tariff', answer: 'a diamond in the rough' },
  { topic: 'phrases', phrase: 'ape isolation ache', answer: 'a piece of cake' },
  { topic: 'phrases', phrase: 'bake tooks queer won', answer: 'back to square one' },
  { topic: 'phrases', phrase: 'bare king carpet round trip', answer: 'barking up the wrong tree' },
  { topic: 'phrases', phrase: 'bee stinger hound their woosh', answer: 'beating around the bush' },
  { topic: 'phrases', phrase: 'purse yawn baby ball', answer: 'burst your bubble' },
  { topic: 'phrases', phrase: 'cloud bark mousey car', answer: 'close but no cigar' },
  { topic: 'phrases', phrase: 'teapot ceiling thekra tell blake', answer: 'the pot calling the kettle black' },
];
