import { LOGO } from './logo';
import { ERROR_COPY, TOPICS } from './constants';
import { PHRASES } from './phrases'
import { Item } from './interfaces';

export class Nonsense {

  private host: HTMLElement;
  private items: Array<Item>;
  private recognition: SpeechRecognition;
  private currentItem: number;
  private timer: number;
  private score: number;

  constructor(host: HTMLElement) {
    this.host = host;
    this.items = [];
    this.recognition = null;
    this.timer = null;
    this.score = 0;
    this.currentItem = 0;
  }

  public init(): void {
    this.checkSupport()
    .then(() => {
      this.render();
      this.bindEvents();
    })
    .catch(() => {
      this.renderError();
    });
  }

  public checkSupport(): Promise<void> {
    return new Promise((resolve, reject) => {
      if ('webkitSpeechRecognition' in window) {
        resolve();
      } else {
        reject();
      }
    });
  }

  public startTimer() {
    let time = 0;
    this.timer = setInterval(() => {
      if (time < 61) {
        ++time;
        const loaderElement = this.host.querySelector('.loader') as HTMLElement;
        if (loaderElement !== null) {
          loaderElement.style.width = `${time * 100 / 60}%`;
        }
      }
      if (time >= 61) {
        this.renderPhraseVerdict();
      }
    }, 1000);
  }

  public renderPhraseVerdict() {
    this.stopRecording().then(() => {
      clearInterval(this.timer);
      const skipButton = this.host.querySelector('#skip');
      if (skipButton !== null) {
        skipButton.remove();
      }
      const loaderElement = this.host.querySelector('.loader') as HTMLElement;
      const buttonContainer = this.host.querySelector('.button-container') as HTMLElement;
      const phraseElement = this.host.querySelector('#phrase');
      if (phraseElement !== null) {
        const answer = this.items[this.currentItem].answer.replace('.', ' ');
        this.host.querySelector('#phrase').innerHTML = answer;
      }
      loaderElement.style.width = '0%';
      this.host.style.background = 'red';
      const buttonText = this.currentItem === this.items.length ? 'Finish' : 'Next phrase';
      buttonContainer.innerHTML = `
        <span class="verdict incorrect block">You missed it!</span>
        <div class="padding-top-2rem">
          <button data-incorrect id="next-phrase" type="button" class="button next">
            ${buttonText}
          </button>
        </div>
      `;
    });
  }

  public renderCorrectVerdict() {
    this.stopRecording().then(() => {
      clearInterval(this.timer);
      const skipButton = this.host.querySelector('#skip');
      if (skipButton !== null) {
        skipButton.remove();
      }
      const loaderElement = this.host.querySelector('.loader') as HTMLElement;
      const buttonContainer = this.host.querySelector('.button-container') as HTMLElement;
      const phraseElement = this.host.querySelector('#phrase');
      if (phraseElement !== null) {
        const answer = this.items[this.currentItem].answer.replace('.', ' ');
        this.host.querySelector('#phrase').innerHTML = answer;
      }
      loaderElement.style.width = '0%';
      this.host.style.background = '#007813';
      const buttonText = this.currentItem === this.items.length ? 'Finish' : 'Next phrase';
      buttonContainer.innerHTML = `
        <span class="verdict correct block">Correct!</span>
        <div class="padding-top-2rem">
          <button data-correct id="next-phrase" type="button" class="button next">
            ${buttonText}
          </button>
        </div>
      `;
    });
  }

  public handleRecording() {
    this.recognition.onresult = (event) => {
      // https://jsfiddle.net/envwao8o/4/
      // https://stackoverflow.com/questions/35112561/speech-recognition-api-duplicated-phrases-on-android
      let speechText = '';
      let finalPhrase = '';
      let interimPhrase = '';
      let result;
      for (let i = 0; i < event.results.length; ++i) {
        result = event.results[i];
        if( result.isFinal ) {
          finalPhrase = finalPhrase.trim() + ' ' + result[0].transcript;
        }
        else {
          interimPhrase = interimPhrase.trim() + ' ' + result[0].transcript;
        }
      }
      speechText = (finalPhrase.trim() + ' ' + interimPhrase.trim()).toLowerCase();
      console.log('speechText', speechText)
      // const speechElement = this.host.querySelector('#speech');
      // if (speechElement !== null) {
      //   speechElement.innerHTML += speechText;
      // }
      const answer = this.items[this.currentItem].answer.toLowerCase();
      if (speechText.includes(answer)) {
        this.renderCorrectVerdict();
      }
    }
    this.recognition.onspeechend = () => {
      const recordingVerdictElement = this.host.querySelector('#verdict');
      if (recordingVerdictElement !== null) {
        recordingVerdictElement.innerHTML = 'Oops. Try again.'
      }
    }
    this.recognition.onnomatch = (event) => {
      console.log('No match');
    }

    this.recognition.onerror = (event) => {
      console.log('Error occurred in speech recognition');
    }
    this.recognition.onend = (event) => {
      if (this.recognition) {
        this.recognition.start();
      }
    }
  }

  public render(): void {
    clearInterval(this.timer);
    this.host.style.background = '#0029ff';
    this.currentItem = 0;
    this.score = 0;
    this.stopRecording();
    this.host.innerHTML = `
      <div class="container">
          <div class="padding-top-3rem padding-bottom-3rem">
          ${LOGO}
          <h1 class="sr">Nonsense</h1>
        </div>
        <div class="flex flex-justify-center padding-top-0-5rem">
          <button id="new" type="button" class="button">
            new game
          </button>
        </div>
        <div class="flex flex-justify-center padding-top-0-5rem">
          <button id="how-to-play" type="button" class="button button-primary">
            how to play
          </button>
        </div>
      </div>
    `;
  }

  public renderError(): void {
    this.host.innerHTML = `
      <div class="container">
        <div class="flex flex-justify-center">
          ${LOGO}
        </div>
        <h1 class="sr">
          Nonsense
        </h1>
        <div class="padding-top-4rem">
          <div class="text-center">
            ${ERROR_COPY}
          </div>
        </div>
      </div>
    `;
  }

  public bindEvents(): void {
    // Disable multitouch zoom
    // https://stackoverflow.com/a/37712966
    this.host.addEventListener('touchstart', (event: any) => {
      if(event.touches.length > 1) {
        event.preventDefault();
      }
    });
    document.addEventListener('keydown', (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        event.preventDefault();
        this.render();
      }
    });
    this.host.addEventListener('click', (event: Event) => {
      const targetEl = event.target as HTMLButtonElement;
      if (targetEl.closest('#new') !== null) {
        this.renderNewGame();
      }
      if (targetEl.closest('#start') !== null) {
        const topic = targetEl.getAttribute('data-topic');
        this.populateItems(topic);
        this.renderPhrase();        
      }
      if (targetEl.closest('[data-action="quit"]') !== null) {
        this.render();
      }
      if (targetEl.closest('#how-to-play') !== null) {
        this.renderHowToPlay();
      }
      if (targetEl.closest('#skip') !== null) {
        this.renderPhraseVerdict();
      }
      if (targetEl.closest('#next-phrase') !== null) {
        const totalItems = this.items.length;
        if (targetEl.hasAttribute('data-correct')) {
          ++this.score;
        }
        ++this.currentItem;
        if (this.currentItem !== totalItems) {
          this.renderPhrase();
        } else {
          this.renderSuccess();
        }
      }
    });
  }

  public populateItems(topic: string): void {
    // Clear the global items before populating
    this.items = [];
    // Find matching topic items
    const items = PHRASES.filter((item: Item) => {
      return item.topic === topic
    });
    // Randomise and take the first 10, then push to global items
    const shuffledItems = items.sort(() => Math.random() - 0.5);
    shuffledItems.slice(0, 10).map((item: Item) => {
      this.items.push(item);
    });
  }

  public stopRecording(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.recognition) {
        this.recognition.abort();
        delete this.recognition;
      }
      resolve();
    });
  }

  public startRecording(): void {
    this.recognition = new window.webkitSpeechRecognition();
    this.recognition.continuous = true;
    this.recognition.interimResults = true;
    this.handleRecording();
    this.recognition.start();
  }

  public renderPhrase(): void {
    this.startTimer();
    this.stopRecording().then(() => {
      this.startRecording();
    })
    this.host.style.background = '#0029ff';
    this.host.innerHTML = `
      <div style="width: 0%;" class="loader"></div>
      <div class="container padding-top-4rem">
        <button data-action="quit" type="button" class="button-close">
         <span class="sr">Close</span>
        </button>
        <button class="button button-skip" id="skip" type="button">
          Skip
        </button>
        <div class="flex flex-align-center flex-column padding-top-0-5rem">
          <h1 id="phrase" class="text-center">
            ${this.items[this.currentItem].phrase}
          </h1>
          <div class="padding-top-2rem button-container text-center">
            <span id="record" data-recording="true" class="button-record recording">
              <span class="record-icon"></span>
            </span>
            <div class="padding-top-3rem text-center" id="verdict"></div>
          </div>
        </div>
        <div id="speech"></div>
      </div>
    `;
  }

  public renderSuccess(): void {
    clearInterval(this.timer);
    this.stopRecording();
    this.host.style.background = '#0029ff';
    this.host.innerHTML = `
      <div class="container padding-top-5rem">
        <button data-action="quit" type="button" class="button-close">
         <span class="sr">Close</span>
        </button>
        <div class="flex flex-align-center flex-column padding-top-0-5rem">
          <h1 id="phrase" class="text-center">
            you got ${this.score} out of ${this.items.length} correct!
          </h1>
          <div class="padding-top-2rem">
            <button class="button" data-action="quit" type="button">
              Ok
            </button>
          </div>
        </div>
      </div>
    `;
  }

  public renderHowToPlay(): void {
    clearInterval(this.timer);
    this.stopRecording();
    this.host.style.background = '#0029ff';
    this.host.innerHTML = `
      <div class="container padding-top-4rem">
        <button data-action="quit" type="button" class="button-close">
         <span class="sr">Close</span>
        </button>
        <div class="flex flex-align-center flex-column padding-top-0-5rem">
          <h1>
            how to play
          </h1>
          <ol>
            <li class="padding-top-1rem">
              use your speech to figure out the jumbled phrase before the timer ends
            </li>
            <li class="padding-top-1rem">
              if you get the answer right, you score a point
            </li>
            <li class="padding-top-1rem">
              try to score as many points as you can
            </li>
          </ol>
        </div>
      </div>
    `;
  }

  public renderNewGame(): void {
    this.host.style.background = '#0029ff';
    this.host.innerHTML = `
      <div class="container text-center">
        <button data-action="quit" type="button" class="button-close">
         <span class="sr">Close</span>
        </button>
        <div class="padding-top-5rem">
          <h2>Please choose a topic</h2>
          <div class="padding-top-1rem" id="topics"></div>
        </div>
      </div>
    `;
    const topicsElement = this.host.querySelector('#topics');
    if (topicsElement !== null) {
      TOPICS.map((topic: string) => {
        const count = PHRASES.filter(phrase => phrase.topic === topic);
        topicsElement.insertAdjacentHTML('afterbegin', `
          <button id="start" data-topic="${topic}" type="button" class="button button-primary button-topic">
            ${topic} (${count.length})
          </button>
        `);
      });
    }
  }
}

// Initialise the class to run
const app = document.querySelector('#app') as HTMLElement;
if (app !== null) {
  const application = new Nonsense(app as HTMLElement);
  application.init();
}
