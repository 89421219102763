export const PHRASES = [
  { topic: 'entertainment', phrase: 'my star seth', answer: 'masterchef' },
  { topic: 'entertainment', phrase: 'dane sing wither sars', answer: 'dancing with the stars' },
  { topic: 'entertainment', phrase: 'blake committee roar', answer: 'black mirror' },
  { topic: 'entertainment', phrase: 'pry sum brick', answer: 'prison break' },
  { topic: 'entertainment', phrase: 'stray jerk inns', answer: 'stranger things' },
  { topic: 'entertainment', phrase: 'brave king bed', answer: 'breaking bad' },
  { topic: 'entertainment', phrase: 'gain oval moans', answer: 'game of thrones' },
  { topic: 'entertainment', phrase: 'there one king dad', answer: 'the walking dead' },
  { topic: 'entertainment', phrase: 'dap beak gang eerie', answer: 'the big bang theory' },
  { topic: 'entertainment', phrase: 'maid amend', answer: 'mad men' },
  { topic: 'entertainment', phrase: 'come unit tee', answer: 'community' },
  { topic: 'entertainment', phrase: 'dork too hoot', answer: 'doctor who' },
  { topic: 'entertainment', phrase: 'thief lash', answer: 'the flash' },
  { topic: 'entertainment', phrase: 'reap ken more tea', answer: 'rick and morty' },
  { topic: 'entertainment', phrase: 'fart gone', answer: 'fargo' },
  { topic: 'entertainment', phrase: 'too end avocado main', answer: 'two and a half men' },
  { topic: 'entertainment', phrase: 'thieve face', answer: 'the office' },
  { topic: 'entertainment', phrase: 'their soup rain nose', answer: 'the sopranos' },
  { topic: 'entertainment', phrase: 'there seem suns', answer: 'the simpsons' },
  { topic: 'entertainment', phrase: 'bat recoil sole', answer: 'better call saul' },
  { topic: 'entertainment', phrase: 'broke line iron canine', answer: 'brooklyn nine-nine' },
  { topic: 'entertainment', phrase: 'howl amen your moth here', answer: 'how i met your mother' },
  { topic: 'entertainment', phrase: 'that why light phone', answer: 'the twilight zone' },
  { topic: 'entertainment', phrase: 'chew deed active', answer: 'true detective' },
  { topic: 'entertainment', phrase: 'debt knot', answer: 'death note' },
  { topic: 'entertainment', phrase: 'lass wheat too naughty whiff yawn liver', answer: 'last week tonight with john oliver' },
  { topic: 'entertainment', phrase: 'winter caesars', answer: 'when they see us' },
  { topic: 'entertainment', phrase: 'one lee tool sand courses', answer: 'only fools and horses' },
  { topic: 'entertainment', phrase: 'month tea pipe thong', answer: 'monty python\'s flying circus' },
  { topic: 'entertainment', phrase: 'grave pity fools', answer: 'gravity falls' },
  { topic: 'entertainment', phrase: 'cream nail blinds', answer: 'criminal minds' },
  { topic: 'entertainment', phrase: 'pea key ply theirs', answer: 'peaky blinders' },
  { topic: 'entertainment', phrase: 'monday highest', answer: 'money heist' },
  { topic: 'entertainment', phrase: 'more than farm early', answer: 'modern family' },
  { topic: 'entertainment', phrase: 'avert arthur lost hair burner', answer: 'avatar the last airbender' },
  { topic: 'entertainment', phrase: 'lord end portal', answer: 'law and order' },
  { topic: 'entertainment', phrase: 'a mayor weekend aura store dirty', answer: 'american horror story' },
  { topic: 'entertainment', phrase: 'bar feeder vain empires delay there', answer: 'buffy the vampire slayer' },
  { topic: 'entertainment', phrase: 'hail tonne galloway women dirt', answer: 'how to get away with murder' },
  { topic: 'entertainment', phrase: 'howls avocados', answer: 'house of cards' },
  { topic: 'entertainment', phrase: 'spoon jobs quail repaints', answer: 'sponge bob square pants' },
  { topic: 'entertainment', phrase: 'test parent mouse whites', answer: 'desperate housewives' },
  { topic: 'entertainment', phrase: 'seymour villain', answer: 'smallville' },
  { topic: 'entertainment', phrase: 'course hippo pearl', answer: 'gossip girl' },
  { topic: 'entertainment', phrase: 'the heart swerve entities shoe', answer: 'that seventies show' },
  { topic: 'entertainment', phrase: 'then height main anger', answer: 'the night manager' },
  { topic: 'entertainment', phrase: 'loose sign fart', answer: 'lucifer' },
  { topic: 'entertainment', phrase: 'stray tree kurt', answer: 'star trek' },
  { topic: 'entertainment', phrase: 'them and her law ribbon', answer: 'the mandalorian' },
  { topic: 'entertainment', phrase: 'are knee milk wing drum', answer: 'animal kingdom' },
  { topic: 'entertainment', phrase: 'thug hold white', answer: 'the good fight' },
  { topic: 'entertainment', phrase: 'fouls atari', answer: 'food safari' },
  { topic: 'entertainment', phrase: 'nail carcass max ego', answer: 'narcos mexico' },
  { topic: 'entertainment', phrase: 'all there red cabin', answer: 'altered carbon' },
  { topic: 'entertainment', phrase: 'farm early fueled', answer: 'family feud' },
  { topic: 'entertainment', phrase: 'their voyage sea', answer: 'the voice' },
  { topic: 'entertainment', phrase: 'a mayors weekend scott pallet', answer: 'americas got talent' },
  { topic: 'entertainment', phrase: 'there patchy lord', answer: 'the bachelor' },
  { topic: 'entertainment', phrase: 'their boulder debut tear foul', answer: 'the bold and the beautiful' },
  { topic: 'entertainment', phrase: 'loom knee two owns', answer: 'looney tunes' },
  { topic: 'games', phrase: 'jerk new came', answer: 'duke nukem' },
  { topic: 'games', phrase: 'coral oval dirty', answer: 'call.of duty' },
  { topic: 'games', phrase: 'sue perm oreo', answer: 'super mario' },
  { topic: 'games', phrase: 'mind car fit', answer: 'minecraft' },
  { topic: 'games', phrase: 'grain seth otto', answer: 'grand theft auto' },
  { topic: 'games', phrase: 'tait rest', answer: 'tetris' },
  { topic: 'games', phrase: 'read deadly assumption', answer: 'red dead redemption' },
  { topic: 'games', phrase: 'hail fly feet', answer: 'half-life' },
  { topic: 'games', phrase: 'gold oval', answer: 'god of war' },
  { topic: 'games', phrase: 'spain van doors', answer: 'space invaders' },
  { topic: 'games', phrase: 'cone turf spike', answer: 'counter-strike' },
  { topic: 'games', phrase: 'row blow kiss', answer: 'roblox' },
  { topic: 'games', phrase: 'residue oval', answer: 'resident evil' },
  { topic: 'games', phrase: 'whirl dove wall cuff', answer: 'world of warcraft' },
  { topic: 'games', phrase: 'they all gentle oval rozella', answer: 'the legend of zelda' },
  { topic: 'games', phrase: 'band joe car zoey', answer: 'banjo kazooie' },
  { topic: 'games', phrase: 'superb math brow theirs', answer: 'super smash brothers' },
  { topic: 'games', phrase: 'pork amen gore', answer: 'pokemon go' },
  { topic: 'games', phrase: 'mum oreo care tee', answer: 'mario kart' },
  { topic: 'games', phrase: 'more tall comb bait', answer: 'mortal kombat' },
  { topic: 'games', phrase: 'blow odd burn', answer: 'bloodborne' },
  { topic: 'games', phrase: 'flame pie burn', answer: 'flappy bird' },
  { topic: 'games', phrase: 'born door lanes', answer: 'borderlands' },
  { topic: 'games', phrase: 'soup here many rode sea', answer: 'super mario odyssey' },
  { topic: 'games', phrase: 'spine term main', answer: 'spider man' },
  { topic: 'games', phrase: 'row kettle egg', answer: 'rocket league' },
  { topic: 'games', phrase: 'streak fleet pie turn', answer: 'street fighter' },
  { topic: 'games', phrase: 'dunk here honk pantry', answer: 'donkey kong country' },
  { topic: 'games', phrase: 'green fade mango', answer: 'grim fandango' },
  { topic: 'games', phrase: 'sigh knack their edge jog', answer: 'sonic the hedgehog' },
  { topic: 'movies', phrase: 'soup as tar', answer: 'superstar' },
  { topic: 'movies', phrase: 'ate vent germs', answer: 'avengers' },
  { topic: 'movies', phrase: 'stay wolves', answer: 'star wars' },
  { topic: 'movies', phrase: 'sow hound oval meow sick', answer: 'sound of music' },
  { topic: 'movies', phrase: 'pull perfect shine', answer: 'pulp fiction' },
  { topic: 'movies', phrase: 'their jaw jank read empty shine', answer: 'the shawshank redemption' },
  { topic: 'movies', phrase: 'wise hard ovals', answer: 'wizard of oz' },
  { topic: 'movies', phrase: 'their dart sight', answer: 'the dark knight' },
  { topic: 'movies', phrase: 'take x-ray try vert', answer: 'taxi driver' },
  { topic: 'movies', phrase: 'jerk rascal parcel ', answer: 'jurassic park' },
  { topic: 'movies', phrase: 'talk too strain', answer: 'doctor strange' },
  { topic: 'movies', phrase: 'their terminal paper', answer: 'the terminator' },
  { topic: 'movies', phrase: 'hoe aim loan', answer: 'home alone' },
  { topic: 'movies', phrase: 'race engine bee all', answer: 'raging bull' },
  { topic: 'movies', phrase: 'too store reak', answer: 'toy story' },
  { topic: 'movies', phrase: 'they cough author', answer: 'the godfather' },
  { topic: 'movies', phrase: 'tell order oval things', answer: 'the lord of the rings' },
  { topic: 'movies', phrase: 'seedy enguard', answer: 'city of god' },
  { topic: 'movies', phrase: 'serving prey vape rain', answer: 'saving private ryan' },
  { topic: 'movies', phrase: 'livers boot fall', answer: 'life is beautiful' },
  { topic: 'movies', phrase: 'theirs voilence oval arms', answer: 'the silence of the lambs' },
  { topic: 'movies', phrase: 'tire tame knees', answer: 'titanic' },
  { topic: 'movies', phrase: 'gone fail last', answer: 'goodfellas' },
  { topic: 'movies', phrase: 'theriac thing', answer: 'the lion king' },
  { topic: 'movies', phrase: 'thirty socks sands', answer: 'the sixth sense' },
  { topic: 'movies', phrase: 'their shore socks peeks', answer: 'the usual suspects' },
  { topic: 'movies', phrase: 'warnings weld', answer: 'waynes world' },
  { topic: 'movies', phrase: 'the hair sown roundabout mayor reed', answer: 'there\'s something about mary' },
  { topic: 'movies', phrase: 'inner centipede name', answer: 'independence day' },
  { topic: 'movies', phrase: 'tray nine tray', answer: 'training day' },
  { topic: 'movies', phrase: 'crow cheat eye girl hayden dandruff', answer: 'crouching tiger hidden dragon' },
  { topic: 'movies', phrase: 'aye mayor weekend cycle', answer: 'american psycho' },
  { topic: 'movies', phrase: 'cars tear way', answer: 'castaway' },
  { topic: 'movies', phrase: 'school mary more vee', answer: 'scary movie' },
  { topic: 'movies', phrase: 'bay tall voyage yell', answer: 'battle royale' },
  { topic: 'movies', phrase: 'bree wing it own', answer: 'bring it on' },
  { topic: 'movies', phrase: 'cheeky can won', answer: 'chicken run' },
  { topic: 'movies', phrase: 'fan nailed test teen action', answer: 'final destination' },
  { topic: 'movies', phrase: 'bean lilly elite', answer: 'billy elliot' },
  { topic: 'movies', phrase: 'ray number that arteries', answer: 'remember the titans' },
  { topic: 'movies', phrase: 'kite oat bar smugly', answer: 'coyote ugly' },
  { topic: 'movies', phrase: 'mash shimmer emotional', answer: 'mission impossible' },
  { topic: 'movies', phrase: 'gourd incense cones', answer: 'gone in 60 seconds' },
  { topic: 'movies', phrase: 'their binge', answer: 'the beach' },
  { topic: 'movies', phrase: 'howl thug winch store wrist mays', answer: 'how the grinch stole christmas' },
  { topic: 'movies', phrase: 'bait four ninety fours', answer: 'before night falls' },
  { topic: 'movies', phrase: 'walt eyes beanie reath', answer: 'what lies beneath' },
  { topic: 'movies', phrase: 'there facial neat wall', answer: 'the social network' },
  { topic: 'movies', phrase: 'blank keystone', answer: 'black swan' },
  { topic: 'movies', phrase: 'hail tonne rain audrey gone', answer: 'how to train your dragon' },
  { topic: 'movies', phrase: 'worn horn dread sand dead treason owls', answer: '127 hours' },
  { topic: 'movies', phrase: 'hair ring put her', answer: 'harry potter' },
  { topic: 'movies', phrase: 'thug car hearse witty her', answer: 'the ghost writer' },
  { topic: 'movies', phrase: 'deans peek apple meal', answer: 'despicable me' },
  { topic: 'movies', phrase: 'elite sing under language', answer: 'alice in wonderland' },
  { topic: 'movies', phrase: 'theatre skies', answer: 'the other guys' },
  { topic: 'movies', phrase: 'clay shuffle their tanks', answer: 'clash of the titans' },
  { topic: 'movies', phrase: 'dabble oval high', answer: 'the book of eli' },
  { topic: 'movies', phrase: 'einstein jock bean', answer: 'inside job' },
  { topic: 'movies', phrase: 'then emulators', answer: 'the new mutants' },
  { topic: 'music', phrase: 'goons are rows zeus', answer: 'guns n roses' },
  { topic: 'music', phrase: 'light tell raid', answer: 'little red' },
  { topic: 'music', phrase: 'blue dime lies', answer: 'blinding lights' },
  { topic: 'music', phrase: 'dans moon kate', answer: 'dance monkey' },
  { topic: 'music', phrase: 'sir cools', answer: 'circles' },
  { topic: 'music', phrase: 'weep wall rope kew', answer: 'we will rock you' },
  { topic: 'music', phrase: 'hip waiter tell', answer: 'highway to hell' },
  { topic: 'music', phrase: 'dane sing wither stray germ', answer: 'dancing with a stranger' },
  { topic: 'music', phrase: 'sue ogar end', answer: 'see you again' },
  { topic: 'music', phrase: 'own lie hugh amend', answer: 'only human' },
  { topic: 'music', phrase: 'growls just one torn haven', answer: 'girls just want to have fun' },
  { topic: 'music', phrase: 'too tall newsclip enter their heart', answer: 'total eclipse in the heart' },
  { topic: 'music', phrase: 'wendorf skies', answer: 'when doves cry' },
  { topic: 'music', phrase: 'hay eel the war led', answer: 'heal the world' },
  { topic: 'music', phrase: 'row oak which suit', answer: 'rock with you' },
  { topic: 'music', phrase: 'gang nasty kyle', answer: 'gangnam style' },
  { topic: 'music', phrase: 'indie hair', answer: 'in the air' },
  { topic: 'music', phrase: 'hade june', answer: 'hey jude' },
  { topic: 'music', phrase: 'know womb manta comply', answer: 'no woman no cry' },
  { topic: 'music', phrase: 'lift own mums', answer: 'life on mars' },
  { topic: 'music', phrase: 'bean mine maybe', answer: 'be my baby' },
  { topic: 'music', phrase: 'spur pearl grain', answer: 'purple rain' },
  { topic: 'music', phrase: 'number one types turducken', answer: 'another one bites the dust' },
  { topic: 'music', phrase: 'work liking election', answer: 'walk like an egyptian' },
  { topic: 'music', phrase: 'lamb shank', answer: 'love shack' },
  { topic: 'music', phrase: 'drain sing inter duck', answer: 'dancing in the dark' },
  { topic: 'music', phrase: 'ivy oval tie girth', answer: 'eye of the tiger' },
  { topic: 'music', phrase: 'kneel ute knight', answer: 'need you tonight' },
  { topic: 'music', phrase: 'harp towl pearl', answer: 'uptown girl' },
  { topic: 'people', phrase: 'jay key shine', answer: 'jackie chan' },
  { topic: 'people', phrase: 'rye antsy caress', answer: 'ryan seacrest' },
  { topic: 'people', phrase: 'talk to drain', answer: 'dr dre' },
  { topic: 'people', phrase: 'tea nut turf are', answer: 'tina turner' },
  { topic: 'people', phrase: 'dough null dump', answer: 'donald trump' },
  { topic: 'people', phrase: 'bee ill gray apes', answer: 'bill gates' },
  { topic: 'people', phrase: 'mine call jaw damp', answer: 'michael jordan' },
  { topic: 'people', phrase: 'laid ear far car', answer: 'lady gaga' },
  { topic: 'people', phrase: 'tait lords wept', answer: 'taylor swift' },
  { topic: 'people', phrase: 'kneel some dalai lama ', answer: 'nelson mandela' },
  { topic: 'people', phrase: 'range leaner jawline', answer: 'angelina jolie' },
  { topic: 'people', phrase: 'wales my trick', answer: 'will smith' },
  { topic: 'people', phrase: 'geoff antelopes', answer: 'jennifer lopez' },
  { topic: 'people', phrase: 'jaw jet envolopes', answer: 'george lopez' },
  { topic: 'people', phrase: 'steak vans pill purge', answer: 'steven spielberg' },
  { topic: 'people', phrase: 'leak narcos decapitated trio', answer: 'leonardo dicaprio' },
  { topic: 'people', phrase: 'tomb fangs', answer: 'tom hanks' },
  { topic: 'people', phrase: 'anaconda hand link calm', answer: 'abraham lincoln' },
  { topic: 'people', phrase: 'all dread hipster fern', answer: 'audrey hepburn' },
  { topic: 'people', phrase: 'knee colic main', answer: 'nicole kidman' },
  { topic: 'people', phrase: 'heel fairy clinch tone', answer: 'hilary clinton' },
  { topic: 'people', phrase: 'sow flame oink', answer: 'sophie monk' },
  { topic: 'people', phrase: 'tray vortex aura', answer: 'trevor noah' },
  { topic: 'people', phrase: 'knee colar sage', answer: 'nicolas cage' },
  { topic: 'people', phrase: 'day vape bread ham', answer: 'david beckham' },
  { topic: 'people', phrase: 'air ending garage day', answer: 'ariana grande' },
  { topic: 'people', phrase: 'day weight judge sun', answer: 'dwayne johnson' },
  { topic: 'people', phrase: 'elf touch on', answer: 'elton john' },
  { topic: 'people', phrase: 'hair rye stick less', answer: 'harry styles' },
  { topic: 'people', phrase: 'case tip fairy', answer: 'katy perry' },
  { topic: 'phrases', phrase: 'bee gig chia ease', answer: 'big cheese' },
  { topic: 'phrases', phrase: 'bed boring keys', answer: 'bad books' },
  { topic: 'phrases', phrase: 'afloat enter the adore', answer: 'a foot in the door' },
  { topic: 'phrases', phrase: 'yell oh breek rude', answer: 'yellow brick road' },
  { topic: 'phrases', phrase: 'toot cot candle', answer: 'too hot to handle' },
  { topic: 'phrases', phrase: 'bum pan green', answer: 'bump and grind' },
  { topic: 'phrases', phrase: 'shoe meter monday', answer: 'show me the money' },
  { topic: 'phrases', phrase: 'took all fiscal', answer: 'too cool for school' },
  { topic: 'phrases', phrase: 'mate kit rate', answer: 'make it rain' },
  { topic: 'phrases', phrase: 'hard monday hinge tariff', answer: 'a diamond in the rough' },
  { topic: 'phrases', phrase: 'ape isolation ache', answer: 'a piece of cake' },
  { topic: 'phrases', phrase: 'bake tooks queer won', answer: 'back to square one' },
  { topic: 'phrases', phrase: 'bare king carpet round trip', answer: 'barking up the wrong tree' },
  { topic: 'phrases', phrase: 'bee stinger hound their woosh', answer: 'beating around the bush' },
  { topic: 'phrases', phrase: 'purse yawn baby ball', answer: 'burst your bubble' },
  { topic: 'phrases', phrase: 'cloud bark mousey car', answer: 'close but no cigar' },
  { topic: 'phrases', phrase: 'teapot ceiling thekra tell blake', answer: 'the pot calling the kettle black' },
  { topic: 'phrases', phrase: 'clair asthma dirt', answer: 'clear as mud' },
  { topic: 'phrases', phrase: 'cats tutor case', answer: 'cut to the chase' },
  { topic: 'phrases', phrase: 'made essay amateur', answer: 'mad as a hatter' },
  { topic: 'phrases', phrase: 'rake york braids', answer: 'rack your brains' },
  { topic: 'phrases', phrase: 'unarmed allege', answer: 'an arm and a leg' },
  { topic: 'phrases', phrase: 'pine north thaught wed', answer: 'paint the town red' },
  { topic: 'phrases', phrase: 'ache shone speckle outer tassel wields', answer: 'actions speak louder than words' },
  { topic: 'phrases', phrase: 'arch hippo yorks holster', answer: 'a chip on your shoulder' },
  { topic: 'phrases', phrase: 'better wing croak antler heart plays', answer: 'between a rock and a hard place' },
  { topic: 'phrases', phrase: 'kite oval spiel meal cat', answer: 'cry over spilled milk' },
  { topic: 'phrases', phrase: 'queue risotto keel vatican', answer: 'curiosity killed the cat' },
  { topic: 'phrases', phrase: 'daybed skews isabella the nun', answer: 'a bad excuse is better than none' },
];
