export const LOGO = `
<svg class="logo" width="282" height="297" viewBox="0 0 282 297" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M219.623 107.866L222.252 107.161C222.932 108.674 224.024 110.099 225.528 111.437C227.074 112.763 228.851 113.753 230.858 114.406C232.897 115.005 234.942 115.03 236.993 114.48C239.216 113.884 240.979 112.771 242.282 111.139C243.586 109.507 243.917 107.494 243.275 105.101C242.794 103.306 241.828 102.007 240.376 101.205C238.967 100.392 236.979 99.8941 234.41 99.7119L229.429 99.3288C225.367 99.043 222.17 98.1362 219.837 96.6084C217.546 95.0692 215.96 92.6541 215.078 89.3632C214.357 86.6706 214.352 84.1753 215.065 81.8772C215.766 79.5363 217.063 77.5396 218.957 75.8871C220.893 74.2231 223.293 73.0075 226.156 72.2402C227.909 71.7707 229.886 71.6301 232.09 71.8184C232.272 71.8153 232.691 71.8405 233.347 71.8938C234.034 71.893 234.676 71.8124 235.275 71.6521C235.873 71.4918 236.25 71.2762 236.407 71.0052C236.606 70.7228 236.718 70.3721 236.743 69.9531L238.859 69.3862L242.437 80.177L239.809 80.8813C238.378 78.1041 236.444 76.1027 234.009 74.8772C231.562 73.609 229.013 73.3298 226.364 74.0399C224.141 74.6354 222.442 75.7319 221.267 77.3294C220.092 78.9269 219.756 80.6659 220.26 82.5464C220.627 83.9141 221.342 84.9593 222.406 85.6819C223.501 86.3504 224.528 86.7625 225.485 86.9181C226.486 87.0623 227.9 87.2102 229.727 87.3619L235.982 87.8846C243.066 88.506 247.404 91.7871 248.995 97.7278C249.843 100.891 249.832 103.756 248.963 106.325C248.082 108.852 246.631 110.958 244.61 112.645C242.577 114.289 240.299 115.449 237.778 116.125C235.726 116.675 233.218 116.889 230.254 116.767C230.029 116.781 229.573 116.789 228.886 116.79C228.188 116.748 227.625 116.784 227.197 116.899C226.257 117.151 225.719 117.707 225.583 118.568L223.468 119.135L219.623 107.866Z" fill="white"/>
  <path d="M176.72 144.117L176.656 144.134L184.3 172.662C184.632 173.902 185.081 174.721 185.646 175.119C186.242 175.463 187.159 175.469 188.399 175.137L190.13 174.673L190.697 176.789L178.644 180.018L178.077 177.903L179.552 177.508C180.578 177.233 181.257 176.776 181.59 176.137C181.954 175.444 181.981 174.52 181.672 173.367L173.34 142.274C173.043 141.163 172.724 140.4 172.384 139.988C172.087 139.563 171.595 139.352 170.909 139.352C170.264 139.342 169.231 139.504 167.81 139.839L167.243 137.724L182.372 133.67L209.743 158.63L209.935 158.579L203.253 133.64C202.703 131.589 201.338 130.855 199.159 131.439L197.364 131.92L196.797 129.805L208.657 126.627L209.224 128.742L208.006 129.069C206.04 129.595 205.332 130.885 205.881 132.936L215.948 170.504L208.511 172.496L176.72 144.117Z" fill="white"/>
  <path d="M224.486 219.568C225.853 219.935 226.859 220.113 227.503 220.102C228.147 220.091 228.618 219.874 228.915 219.45C229.255 219.037 229.573 218.275 229.871 217.163L238.134 186.327C238.431 185.216 238.536 184.397 238.449 183.869C238.415 183.31 238.122 182.865 237.569 182.534C237.017 182.203 236.057 181.854 234.689 181.488L235.256 179.372L269.811 188.631L267.114 198.696L264.485 197.992C264.514 195.663 264.287 193.862 263.803 192.587C263.375 191.281 262.613 190.275 261.52 189.57C260.469 188.876 258.918 188.254 256.866 187.705L252.443 186.519C251.588 186.29 250.922 186.295 250.446 186.534C250.024 186.742 249.704 187.252 249.487 188.064L245.673 202.296L253.302 204.34C254.456 204.649 255.372 204.734 256.051 204.595C256.741 204.414 257.344 203.957 257.861 203.225C258.378 202.493 258.966 201.323 259.627 199.713L261.742 200.28L257.808 214.961L255.693 214.394C255.98 212.639 256.061 211.309 255.937 210.405C255.867 209.471 255.573 208.773 255.055 208.314C254.536 207.854 253.7 207.47 252.546 207.161L244.917 205.117L241.138 219.221C240.783 220.545 240.638 221.514 240.703 222.127C240.822 222.709 241.28 223.221 242.078 223.664C242.918 224.118 244.385 224.626 246.479 225.187C248.702 225.782 250.603 226.04 252.182 225.959C253.804 225.889 255.278 225.345 256.604 224.327C257.931 223.308 259.144 221.686 260.244 219.461L262.873 220.166L259.114 231.115L223.919 221.684L224.486 219.568Z" fill="white"/>
  <path d="M141.128 112.014C142.355 111.306 143.192 110.721 143.64 110.258C144.088 109.795 144.267 109.308 144.177 108.798C144.125 108.266 143.811 107.502 143.236 106.505L127.274 78.8583C126.699 77.862 126.194 77.2084 125.759 76.8975C125.34 76.5262 124.818 76.4187 124.193 76.5752C123.568 76.7316 122.643 77.1639 121.416 77.8718L120.321 75.975L151.302 58.0882L156.512 67.1123L154.156 68.4729C152.529 66.8062 151.095 65.693 149.852 65.1333C148.625 64.5131 147.376 64.3403 146.104 64.6149C144.87 64.8673 143.334 65.5245 141.494 66.5865L137.528 68.8762C136.762 69.3187 136.295 69.7928 136.127 70.2984C135.975 70.7437 136.109 71.3303 136.53 72.0584L143.897 84.8186L150.737 80.8695C151.771 80.2722 152.48 79.6845 152.861 79.1066C153.221 78.4903 153.324 77.7407 153.172 76.8578C153.02 75.9749 152.608 74.7309 151.937 73.1258L153.834 72.0307L161.433 85.1933L159.537 86.2884C158.498 84.8441 157.616 83.8464 156.889 83.2954C156.178 82.6838 155.477 82.3988 154.786 82.4403C154.095 82.4818 153.232 82.8012 152.197 83.3986L145.357 87.3476L152.658 99.9929C153.344 101.181 153.926 101.968 154.405 102.356C154.901 102.683 155.587 102.721 156.464 102.47C157.379 102.197 158.776 101.519 160.653 100.435C162.646 99.2843 164.172 98.1223 165.231 96.9486C166.329 95.7527 166.987 94.3256 167.205 92.6672C167.422 91.0088 167.133 89.0041 166.338 86.6532L168.695 85.2926L173.779 95.6921L142.224 113.911L141.128 112.014Z" fill="white"/>
  <path d="M118.961 196.121L121.317 197.481C120.729 199.032 120.493 200.812 120.61 202.821C120.766 204.852 121.322 206.809 122.28 208.69C123.298 210.555 124.727 212.018 126.566 213.08C128.559 214.231 130.593 214.69 132.668 214.458C134.744 214.225 136.401 213.036 137.64 210.891C138.569 209.281 138.804 207.68 138.345 206.086C137.923 204.514 136.869 202.756 135.182 200.811L131.931 197.018C129.261 193.944 127.641 191.042 127.071 188.312C126.54 185.604 127.126 182.774 128.83 179.824C130.223 177.41 131.985 175.642 134.114 174.521C136.265 173.362 138.594 172.867 141.101 173.037C143.647 173.23 146.204 174.067 148.771 175.549C150.342 176.456 151.84 177.756 153.265 179.447C153.396 179.574 153.675 179.888 154.1 180.389C154.587 180.874 155.098 181.272 155.635 181.581C156.171 181.891 156.59 182.005 156.893 181.924C157.233 181.866 157.56 181.697 157.874 181.418L159.771 182.513L154.671 192.674L152.315 191.314C153.266 188.338 153.315 185.555 152.459 182.967C151.625 180.34 150.021 178.34 147.645 176.969C145.652 175.818 143.676 175.392 141.715 175.691C139.755 175.989 138.288 176.982 137.314 178.668C136.606 179.894 136.373 181.139 136.614 182.402C136.916 183.649 137.351 184.667 137.918 185.454C138.523 186.263 139.419 187.368 140.603 188.767L144.657 193.56C149.226 199.008 149.973 204.395 146.898 209.722C145.261 212.557 143.227 214.576 140.796 215.778C138.387 216.942 135.871 217.405 133.249 217.169C130.649 216.894 128.218 216.104 125.957 214.798C124.118 213.736 122.193 212.114 120.183 209.932C120.014 209.783 119.686 209.466 119.2 208.981C118.736 208.457 118.312 208.085 117.929 207.864C117.086 207.377 116.312 207.39 115.607 207.903L113.71 206.808L118.961 196.121Z" fill="white"/>
  <path d="M20.3527 187.032L20.2953 187.065L35.0627 212.643C35.7042 213.754 36.3494 214.429 36.998 214.668C37.6629 214.846 38.551 214.614 39.6622 213.973L41.2141 213.077L42.3092 214.973L31.5033 221.212L30.4082 219.315L31.7302 218.552C32.6499 218.021 33.1877 217.404 33.3437 216.701C33.5159 215.937 33.3034 215.038 32.7061 214.004L16.6112 186.127C16.036 185.13 15.5309 184.477 15.096 184.166C14.6994 183.833 14.1694 183.756 13.5061 183.934C12.8812 184.091 11.9254 184.515 10.6387 185.206L9.5436 183.31L23.1085 175.478L56.0065 192.504L56.179 192.405L43.27 170.045C42.208 168.206 40.6999 167.851 38.7457 168.979L37.1363 169.908L36.0412 168.011L46.6747 161.872L47.7698 163.769L46.6777 164.399C44.915 165.417 44.5646 166.845 45.6266 168.685L65.0731 202.367L58.4056 206.217L20.3527 187.032Z" fill="white"/>
  <path d="M86.3406 157.685C79.3741 155.818 74.4929 152.495 71.6968 147.714C68.9436 142.946 68.5117 137.035 70.4013 129.983C72.2909 122.931 75.62 118.029 80.3889 115.275C85.2004 112.533 91.0895 112.096 98.056 113.962C105.022 115.829 109.882 119.147 112.636 123.916C115.432 128.696 115.885 134.612 113.995 141.664C112.106 148.716 108.755 153.613 103.944 156.355C99.1748 159.108 93.3071 159.551 86.3406 157.685ZM86.9075 155.569C90.6685 156.577 93.7207 155.7 96.064 152.938C98.4616 150.145 100.571 145.35 102.392 138.555C104.167 131.93 104.656 126.771 103.859 123.076C103.117 119.35 100.994 117.017 97.4891 116.078C93.728 115.07 90.6488 115.963 88.2512 118.756C85.9079 121.518 83.8258 126.297 82.005 133.092C80.1841 139.888 79.5922 145.089 80.2292 148.695C80.9203 152.27 83.1464 154.561 86.9075 155.569Z" fill="white"/>
  <path d="M8.29346 79.8751H8.22709V109.41C8.22709 110.693 8.44832 111.6 8.89079 112.131C9.37751 112.618 10.2624 112.861 11.5456 112.861H13.3376V115.051H0.859974V112.861H2.38649C3.44842 112.861 4.22274 112.596 4.70946 112.065C5.24042 111.49 5.5059 110.605 5.5059 109.41V77.2203C5.5059 76.0699 5.39528 75.2513 5.17405 74.7646C4.99706 74.2779 4.57672 73.9461 3.91301 73.7691C3.29355 73.5921 2.25375 73.4815 0.793604 73.4372V71.247H16.457L36.4345 102.441H36.6336V76.623C36.6336 74.4991 35.5053 73.4372 33.2487 73.4372H31.3903V71.247H43.6689V73.4372H42.4078C40.3725 73.4372 39.3548 74.4991 39.3548 76.623V115.516H31.6558L8.29346 79.8751Z" fill="white"/>
  <path d="M239.677 282.228C241.273 277.641 242.846 272.258 246.147 268.154C247.643 266.294 254.003 273.754 254.835 274.419C258.251 277.148 263.782 281.438 268.485 275.959C271.393 272.571 271.862 267.523 272.816 263.68" stroke="#B4E4FF" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
  <rect x="4.74033" y="17.6289" width="18.963" height="35.5556" rx="9.48148" transform="rotate(-30 4.74033 17.6289)" fill="#FFB186"/>
  <rect x="45.1616" y="253.844" width="18.963" height="52.1482" rx="9.48148" transform="rotate(60 45.1616 253.844)" fill="#FFCC00"/>
  <rect x="173.728" y="12.3346" width="18.963" height="18.963" rx="9.48148" transform="rotate(-30 173.728 12.3346)" fill="#FF8D86"/>
  <path d="M132.509 264.738C129.891 260.203 131.444 254.405 135.979 251.786V251.786C140.514 249.168 146.313 250.722 148.931 255.257L158.413 271.679C161.031 276.214 159.477 282.013 154.942 284.631V284.631C150.407 287.249 144.609 285.696 141.99 281.161L132.509 264.738Z" fill="#FFF386"/>
  <rect x="113.5" y="22.4664" width="23.7037" height="23.7037" rx="11.8519" transform="rotate(75 113.5 22.4664)" fill="#FFF386"/>
  <path d="M242.573 11.8927L271.855 21.4145L248.968 42.0133L242.573 11.8927Z" fill="#FBCBFF"/>
</svg>
`;
